import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

import Badge from 'shopper/components/Badge';

import { hasHighlightBadge } from 'lib/offer';

import OFFER from 'constants/offer';

const OfferCardBadges = ({ offerIsHighlight, offerStatusName, className }) => (
  <div className={twMerge('absolute top-0 flex justify-end', className)}>
    <div className="z-10 flex flex-wrap">
      {offerStatusName === OFFER.STATUSES.TOP_OFFER &&
        !hasHighlightBadge(offerIsHighlight, offerStatusName) && (
          <Badge type="highlight">{OFFER.BADGES.LOWEST_PRICE}</Badge>
        )}
      {offerStatusName === OFFER.STATUSES.FINISHED && (
        <Badge>{OFFER.BADGES.FINISHED}</Badge>
      )}
      {hasHighlightBadge(offerIsHighlight, offerStatusName) && (
        <Badge type="primary">{OFFER.BADGES.RECOMMENDED}</Badge>
      )}
    </div>
  </div>
);

OfferCardBadges.propTypes = {
  offerIsHighlight: PropTypes.bool.isRequired,
  offerStatusName: PropTypes.string.isRequired,
};

export default OfferCardBadges;
